<template>
    <iframe class="h-100 w-100" :src="url" ref="viewerRef" />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { GlobalMessenger } from 'o365-modules';

export interface Props {
    fileName?: string,
    primKey?: string,
    viewName?: string,
    readOnly?: boolean,
    viewerOnly?: boolean
    openBlob?:string,
    pdffileRef?:string
};


const encodeObject = (pValue: any) => {
    const jsonString = JSON.stringify({
        fileName: props.fileName,
        primKey: props.primKey,
        viewName: props.viewName,
        readOnly: props.readOnly,
        viewerOnly: props.viewerOnly,
        openBlob: props.openBlob,
        fileRef: props.pdffileRef
        // time_stamp: Date.now() //for performance enhancement, using pdffileref instead
    });
    const encodedString = btoa(encodeURIComponent(jsonString));
    return encodedString;
}
const props = defineProps<Props>();
const url = computed(() => { return `/nt/foxit9-viewer?params=${encodeObject(props)}${props.pdffileRef ? '' : '&TimeStamp='+Date.now()}`})
const viewerRef = ref(null);
const initiallyLoaded = ref(false);

function loadDocument(){
    viewerRef.value.contentWindow.postMessage(`{"action": "LOAD_NEW_PDF", "props": "${encodeObject(props)}"}`);
}

GlobalMessenger.on(async (pPayload) => {
    if (pPayload === "PDFUI_CREATED") {
        initiallyLoaded.value = true;
    }
})

defineExpose({
    loadDocument
})
</script>